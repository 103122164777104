import React from 'react';
import classNames from 'classnames';

import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import styles from './infoReminderStyles.cssm';

export const InfoReminder = ({
  colorTheme = 'gold',
  iconPath,
  dataE2e,
  infoMessage,
}) => {
  return (
    <div
      className={classNames(
        'flex justify-between py2 my3',
        styles.borderStyle,
        {
          [styles.goldStyle]: colorTheme === 'gold',
        }
      )}
    >
      <Icon className={`self-center ml3`} path={iconPath} />
      <span className="ml2 px2 py1 text-xxs text-semibold" data-e2e={dataE2e}>
        {infoMessage}
      </span>
    </div>
  );
};
